import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { navigationRoutes } from "../constants/constants";

export const ChangelogComponent = () => {
    document.title = "Changelog | PocketQuant";
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const features = [
        {
            title: 'Launch!',
            description: (
                <>
                    <p>This marks the first day in what I hope is a long and fortuitous adventure.</p>
                    <p>I invite you to join me on this journey of mine where I'll be building a product I've wanted for a very long time.</p>
                    <p>I envision PocketQuant as being a place where curious individuals, such as myself,</p>
                    <p>can come to ask questions, run experiments, and deploy code that reacts to the outside world, trading all our greatest ideas.</p>
                    <br />
                    <p>As of today PQ supports using LLMs to ask questions about earnings releases and earnings call transcripts</p>
                    <br />
                    <p>In the near future I plan on adding:</p>
                    <br />
                    <ul className="list-disc list-inside bg-gray-100 p-4 rounded-lg shadow-lg">
                        <li className="py-1">Bespoke datasets (E.g. congressional trades, satellite imagery)</li>
                        <li className="py-1">Function calling (E.g. how does AMZN's price change 3 weeks after its earnings are released)</li>
                        <li className="py-1">Save functions and run them on a schedule or when something happens</li>
                        <li className="py-1">Multiple large language models</li>
                        <li className="py-1">Live earnings calls</li>
                        <li className="py-1">Ability for user to choose datasets in conversation</li>
                    </ul>
                </>
            ),
            date: 'July 30, 2024',
        },
        {
            title: 'Added support for more transcripts',
            description: 'Increased the number of transcripts in the database to 2,153 across 425 companies.',
            date: 'August 6, 2024',
        },
        {
            title: 'Over 3000 transcripts!',
            description: 'PocketQuant now supports asking questions about over 3,300 transcripts spanning 453 companies going as far back as 2009.',
            date: 'August 13, 2024',
        },
        {
            title: 'Small tweaks & changes',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>Implemented some user feedback on confusing flows</li>
                        <li>Gave more flexibility to mobile users for what they can view on the dashboard (select from earnings, transcript, llm conversation)</li>
                        <li>Added a free credit counter</li>
                        <li>Added ticker and logo to conversation column</li>
                    </ul>
                </>,
            date: 'August 20, 2024',
        },
        {
            title: 'Big improvements to citations',
            description:
                <>
                    Citations now include longer more accurate excerpts from the transcripts
                </>,
            date: 'September 17, 2024'
        },
        {
            title: 'Bug fixes & improvements',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Added a <a href="/try-it">Try It</a> page to let users get a feel for the app
                        </li>
                        <li>
                            Fixed some race conditions and other bugs on the backend
                        </li>
                        <li>
                            Refactored frontend to make it more extensible and cleaner
                        </li>
                    </ul>
                </>,
            date: 'September 22, 2024'
        },
        {
            title: 'New high score on Financebench Top 100',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Achieved new <a href="pq-v-chatgpt" className="text-blue-500">high score</a> on the FinanceBench Top 100 benchmark of 71%
                        </li>
                        <li>
                            Improved fault tolerance of data engine and re-designed how data ingestion is triggered
                        </li>
                        <li>
                            Improved coverage of earnings reports from ~23,000 to ~28,000 and transcripts from ~3,300 to ~5,700
                        </li>
                        <li>
                            Improved data retrieval mechanisms for the LLMs
                        </li>
                    </ul>
                </>,
            date: 'Oct 14, 2024'
        },
        {
            title: 'New high score on Financebench Top 100',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Achieved new <a href="pq-v-chatgpt" className="text-blue-500">high score</a> on the FinanceBench Top 100 benchmark of 77%
                        </li>
                        <li>
                            Added features to improve transparency of the LLMs by showing how they got the data that informs their answers
                        </li>
                    </ul>
                </>,
            date: 'Nov 15, 2024'
        },
        {
            title: "Charts & Graphs!",
            description: 
            <>
                <p>
                    Added support for creating and exporting charts and graphs using <b>any</b> of the data that PQ has available. Including:
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Earnings data
                        </li>
                        <li>
                            Revenue by business segment
                        </li>
                        <li>
                            Revenue by geography
                        </li>
                        <li>
                            Major customers
                        </li>
                        <li>
                            And more
                        </li>
                    </ul>
                    try it <a className="underline text-blue-500" href="/try-it">here</a>
                </p>
            </>,
            date: 'Nov 27, 2024'
        },
        {
            title: 'New high score on Financebench Top 100',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Achieved new <a href="pq-v-chatgpt" className="text-blue-500">high score</a> on the FinanceBench Top 100 benchmark of 79%
                        </li>
                    </ul>
                </>,
            date: 'Dec 5, 2024'
        },
        {
            title: 'New high score on Financebench Top 100',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Achieved new <a href="pq-v-chatgpt" className="text-blue-500">high score</a> on the FinanceBench Top 100 benchmark of 87%
                        </li>
                    </ul>
                </>,
            date: 'Dec 6, 2024'
        },
        {
            title: 'Announcing Artifacts',
            description:
                <>
                    <ul className="list-disc list-inside p-4 rounded-lg">
                        <li>
                            Released the artifacts feature which allows users to save images and code outputs to the cloud so they can view them later even if they delete the conversation they were created in. Users can also edit the code they save and update it.
                         </li>
                    </ul>
                </>,
            date: 'Jan 8, 2025'
        },
    ].reverse();

    return (
        <div className="bg-white">
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">PocketQuant</span>
                            <img className="h-8 w-auto" src="pq-icon-512.png" alt="" />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigationRoutes.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <a href="/signup" className="text-sm font-semibold leading-6 text-gray-900">
                            Sign Up <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </nav>
                <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">PocketQuant</span>
                                <img className="h-8 w-auto" src="pq-icon-512.png" alt="" />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigationRoutes.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    <a
                                        href="/login"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Log in
                                    </a>
                                </div>
                                <div className="py-6">
                                    <a
                                        href="/signup"
                                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Signup
                                    </a>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>

            <div className="relative isolate px-6 pt-14 lg:px-8">
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff7f50] to-[#ffa500] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>

                <div className="flex flex-col mt-16 items-center space-y-8 px-4 md:px-0">
                    {features.map((feature, index) => (
                        <div key={index} className="relative w-full max-w-3xl flex">
                            <div className="flex-shrink-0 flex flex-col items-center">
                                <div className="bg-orange-500 rounded-full w-6 h-6 flex items-center justify-center text-white font-bold">
                                    {features.length - index}
                                </div>
                                {index < features.length - 1 && (
                                    <div className="flex-grow w-0.5 bg-gray-300" />
                                )}
                            </div>
                            <div className="ml-8 flex-grow">
                                <div className="text-xl font-semibold">{feature.title}</div>
                                <div className="text-gray-600">{feature.description}</div>
                                <div className="text-sm text-gray-400 mt-2">{feature.date}</div>
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff7f50] to-[#ffa500] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
