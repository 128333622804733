import { useEffect, useState } from "react"
import { EarningsReportComponent } from "./earnings-report-component"
import { MainBackground } from "./main-background"
import { TranscriptComponent } from "./transcript-component"
import { Conversation } from "../api/models/Conversation"
import { LlmConvoComponent } from "./llm-convo-component"
import { useNavigate } from "react-router-dom"
import { funnelRoutes } from "../constants/constants"
import GoogleLoginButton from "./google-login-button"
import { BundledLanguage, BundledTheme, HighlighterGeneric } from "shiki/types.mjs"
import { createHighlighter } from "shiki/index.mjs"
import { EarningsDate } from "../api/models/EarningsDate"
import { AppleEarnings20240630, AppleRevenueByGeorgraphyConvo20240630, AppleSampleEarningsDates, AppleSampleTranscriptDates, AppleTranscript20240630 } from "../sample_assets/apple-revenue-by-geography-convo"
import { EarningsReport } from "../api/models/EarningsReport"
import { Transcript } from "../api/models/Transcript"
import { TslaEarnings20240930, TslaEarningsDates, TslaPowerAndStorageRevenueConvo20240930, TslaTranscript20240930, TslaTranscriptDates } from "../sample_assets/tsla-generation-and-storage-revenue-convo"
import { NvdaDataCenterRevenueConvo20241027, NvdaEarnings20240728, NvdaEarningsDates, NvdaTranscript20241027, NvdaTranscriptDates } from "../sample_assets/nvda-data-center-revenue-convo"
import ImageModal, { ImgModalState } from "./image-modal"
import { CodeModal } from "./code-modal"
import { v4 } from "uuid"
import NotificationPopup from "./notification-popup-component"

interface TryItState {
    activeTab: string;
    conversation: Conversation
    earningsReport: EarningsReport;
    transcript: Transcript;
    earningsDates: EarningsDate[];
    transcriptDates: EarningsDate[];
}

export const TryIt = () => {
    document.title = "Try It | PocketQuant";
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => setIsModalOpen(false);
    const openModal = () => setIsModalOpen(true);
    const navigate = useNavigate();
    const [highlighter, setHighlighter] = useState<HighlighterGeneric<BundledLanguage, BundledTheme>>()
    const [message, setMessage] = useState<string>("");
    const tabs = ["Apple Revenue by Geography", "Tesla Generation & Storage Revenue", "NVDA Data Center Revenue"];
    const [currentCode, setCurrentCode] = useState<string | null>(null);
    const [imgModalState, setImgModalState] = useState<ImgModalState>({
        isImageModalOpen: false,
        modalImageSrc: '',
    });

    const [tryItState, setTryItState] = useState<TryItState>({
        activeTab: tabs[0],
        conversation: AppleRevenueByGeorgraphyConvo20240630,
        earningsDates: AppleSampleEarningsDates,
        transcriptDates: AppleSampleTranscriptDates,
        earningsReport: AppleEarnings20240630,
        transcript: AppleTranscript20240630
    })

    const localNavigation = funnelRoutes.filter(x => x.href !== '/try-it');

    const getCitationTextDetails = async (conversationId: string, messageId: string, fileId: string): Promise<string> => {
        const msg = tryItState?.conversation?.messages?.find(x => x.id === messageId);
        if (!msg) {
            return 'error getting citation text';
        }

        const allFileSearches = msg.runDetails.flatMap((detail: any) =>
            detail.type === "tool_calls"
                ? detail.tool_calls.filter((call: any) => call.type === "file_search")
                : []
        );

        let searchesForThisFile = [];

        for (let i = 0; i < allFileSearches.length; i++) {
            for (let j = 0; j < allFileSearches[i].file_search.results.length; j++) {
                if (allFileSearches[i].file_search.results[j].file_id === fileId) {
                    searchesForThisFile.push(allFileSearches[i].file_search.results[j]);
                }
            }
        }

        let concatenatedText = "";
        for (let i = 0; i < searchesForThisFile.length; i++) {
            concatenatedText += searchesForThisFile[i].content[0].text;
        }

        return concatenatedText;
    }

    const createArtifact = async (type: 'image' | 'code', data: string) => {
        openModal();
        return;
    }

    const sendMessage = async (msg: string): Promise<boolean> => {
        openModal();
        return true;
    }

    const openCodeModal = (code: string) => {
        setCurrentCode(code);
        setIsModalOpen(true);
    };

    const closeCodeModal = () => {
        setCurrentCode(null);
        setIsModalOpen(false);
    };


    useEffect(() => {
        createHighlighter({ themes: ["github-dark"], langs: ["python"] }).then((h) => {
            setHighlighter(h);
        });
    }, []);

    return (
        <>
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative">
                        {/* Close button */}
                        <button
                            className="absolute top-3 right-3 text-gray-500"
                            onClick={closeModal}
                        >
                            &times;
                        </button>

                        {/* Modal Content */}
                        <h2 className="text-xl font-semibold mb-4 justify-center content-center text-center">Login/Sign Up</h2>
                        {/* Login Button */}
                        <div className="flex justify-around">
                            <GoogleLoginButton label='Sign up with Google' additionalStyles={{
                            }} onClick={(e: any) => {
                                e.preventDefault();
                                fetch(`${process.env.REACT_APP_API_URL}/v1/users/google`, {
                                    method: "POST",
                                    headers: {
                                        'X-Request-ID': v4(),
                                        "Content-Type": "application/json",
                                    },
                                })
                                    .then((res) => res.json())
                                    .then((res) => {
                                        window.location.href = res.url;
                                    });
                            }} />
                            {/* Signup Button */}
                            <button
                                onClick={() => navigate('/signup')}
                                className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition duration-300"
                            >
                                Sign Up
                            </button>

                        </div>
                    </div>
                </div>
            )}
            <NotificationPopup />
            <ImageModal imageSrc={imgModalState.modalImageSrc} isOpen={imgModalState.isImageModalOpen} onClose={() => {
                setImgModalState({ ...imgModalState, isImageModalOpen: false });
            }} imgName={imgModalState.fileName} key={"img-modal"} />
            {currentCode && (
                <CodeModal
                    isOpen={isModalOpen}
                    code={currentCode}
                    onClose={closeCodeModal}
                    highlighter={highlighter}
                    language='python'
                    openCodeModal={openCodeModal}
                    createArtifact={createArtifact}
                />
            )}
            <MainBackground>
                <div className="grid grid-rows-[1fr_auto] h-screen overflow-hidden">
                    <div className="inset-x-0 p-4 lg:px-4 top-0 z-[20] flex items-center justify-center" aria-label="Global">
                        <div className="flex gap-x-6 lg:gap-x-12">
                            {localNavigation.map((item) => (
                                <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-center">
                        {tabs.map((tab) => (
                            <button
                                key={tab}
                                onClick={() => {
                                    if (tab === "Apple Revenue by Geography") {
                                        setTryItState({
                                            activeTab: tab,
                                            conversation: AppleRevenueByGeorgraphyConvo20240630,
                                            earningsDates: AppleSampleEarningsDates,
                                            earningsReport: AppleEarnings20240630,
                                            transcript: AppleTranscript20240630,
                                            transcriptDates: AppleSampleTranscriptDates
                                        });
                                    } else if (tab === "Tesla Generation & Storage Revenue") {
                                        setTryItState({
                                            activeTab: tab,
                                            conversation: TslaPowerAndStorageRevenueConvo20240930,
                                            earningsDates: TslaEarningsDates,
                                            earningsReport: TslaEarnings20240930,
                                            transcript: TslaTranscript20240930,
                                            transcriptDates: TslaTranscriptDates
                                        });
                                    } else if (tab === "NVDA Data Center Revenue") {
                                        setTryItState({
                                            activeTab: tab,
                                            conversation: NvdaDataCenterRevenueConvo20241027,
                                            earningsDates: NvdaEarningsDates,
                                            earningsReport: NvdaEarnings20240728,
                                            transcript: NvdaTranscript20241027,
                                            transcriptDates: NvdaTranscriptDates
                                        })
                                    }
                                }}
                                className={`px-4 py-2 text-sm font-medium rounded-md transition 
                        ${tryItState.activeTab === tab
                                        ? "bg-orange-500 text-white"
                                        : "text-gray-600 hover:text-orange-600"
                                    }`}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>
                    {/* Top Row */}
                    <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4 p-4 overflow-hidden">
                        {/* Earnings Statement */}
                        <div className="bg-white/[0.2] hidden md:block rounded-lg shadow p-4 overflow-y-auto">
                            <EarningsReportComponent currentFiscalDateEnding={tryItState.earningsDates[1].date} earnings={tryItState.earningsReport} earningsDates={tryItState.earningsDates} setCurrentFiscalDateEnding={() => { }} />
                        </div>
                        {/* LLM Chat */}
                        <div className="bg-white/[0.2] rounded-lg shadow p-4 overflow-y-auto">
                            <LlmConvoComponent createArtifact={createArtifact} openCodeModal={openCodeModal} setImgModalState={setImgModalState} convo={tryItState.conversation} getCitationTextDetails={getCitationTextDetails} message={message} setMessage={setMessage} sendMessage={sendMessage} />
                        </div>
                        {/* Transcript */}
                        <div className="bg-white/[0.2] hidden md:block rounded-lg shadow p-4 overflow-y-auto">
                            <TranscriptComponent ticker={tryItState.conversation.ticker} currentFiscalDateEnding={tryItState.earningsDates[1].date} earningsDates={tryItState.transcriptDates} setCurrentFiscalDateEnding={(date: string) => { }} transcript={tryItState.transcript} />
                        </div>
                    </div>
                </div>
            </MainBackground>
        </>
    )
}