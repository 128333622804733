import { useState } from 'react';
import { EarningsReport } from '../api/models/EarningsReport';
import { EarningsDate } from '../api/models/EarningsDate';

export const EarningsReportComponent = ({ earnings, currentFiscalDateEnding, setCurrentFiscalDateEnding, earningsDates }:
    {
        earnings: EarningsReport | undefined;
        currentFiscalDateEnding: string;
        setCurrentFiscalDateEnding: (date: string) => void;
        earningsDates: EarningsDate[];
    }) => {
    const [tooltip, setTooltip] = useState({ visible: false, title: '', position: { x: 0, y: 0 } });

    const camelCaseToTitleCase = (str: string) => {
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
    };

    const formatDollarAmount = (amount: string) => {
        return `${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    };

    const formatAmount = (key: string, amount: string) => {
        if (amount === "None" || key === "reportedCurrency" || key === 'fiscalDateEnding') return amount;
        return `$${formatDollarAmount(amount)}`;
    };

    const showTooltip = (title: string, event: React.MouseEvent) => {
        setTooltip({
            visible: true,
            title: title,
            position: { x: event.clientX, y: event.clientY }
        });
    };

    const hideTooltip = () => {
        setTooltip({ visible: false, title: '', position: { x: 0, y: 0 } });
    };

    return (
        <div className="flex flex-col h-full overflow-hidden">
            {/* Sticky Header */}
            <div className="flex justify-between items-center mb-4 sticky top-0 z-10 p-4">
                <h1 className="text-2xl font-bold">Earnings Report</h1>
                <div className="relative w-48">
                    <select
                        className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 appearance-none"
                        value={currentFiscalDateEnding}
                        onChange={(e) => {
                            setCurrentFiscalDateEnding(e.target.value);
                        }}
                    >
                        {earningsDates?.map((item) => (
                            <option
                                disabled={!item.userHasAccess}
                                key={item.date}
                                value={item.date}
                            >
                                {item.date} ({item.fiscalPeriod}-'{item.fiscalYear?.substring(2, 4)})
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Scrollable Content */}
            <div className="flex-1 overflow-y-auto p-4 space-y-6">
                <div>
                    <h2 className="text-xl font-semibold mb-2">Balance Sheet</h2>
                    <div className="space-y-1">
                        {earnings &&
                            earnings.balanceSheet &&
                            Object.entries(earnings.balanceSheet).map(
                                (b) =>
                                    b[0] !== 'fiscalDateEnding' && (
                                        <div
                                            key={b[0]}
                                            className="flex justify-between text-gray-700"
                                        >
                                            <p
                                                className="truncate w-1/2"
                                                onMouseOver={(e) => {
                                                    showTooltip(camelCaseToTitleCase(b[0]), e);
                                                }}
                                                onMouseOut={hideTooltip}
                                            >
                                                {camelCaseToTitleCase(b[0])}
                                            </p>
                                            <p className="truncate w-1/2 text-right">
                                                {formatAmount(b[0], b[1])}
                                            </p>
                                        </div>
                                    )
                            )}
                    </div>
                </div>
                <div>
                    <h2 className="text-xl font-semibold mb-2">Income Statement</h2>
                    <div className="space-y-1">
                        {earnings &&
                            earnings.incomeStatement &&
                            Object.entries(earnings.incomeStatement).map(
                                (b) =>
                                    b[0] !== 'fiscalDateEnding' && (
                                        <div
                                            key={b[0]}
                                            className="flex justify-between text-gray-700"
                                        >
                                            <p
                                                className="truncate w-1/2"
                                                onMouseOver={(e) => {
                                                    showTooltip(camelCaseToTitleCase(b[0]), e);
                                                }}
                                                onMouseOut={hideTooltip}
                                            >
                                                {camelCaseToTitleCase(b[0])}
                                            </p>
                                            <p className="truncate w-1/2 text-right">
                                                {formatAmount(b[0], b[1])}
                                            </p>
                                        </div>
                                    )
                            )}
                    </div>
                </div>
                <div>
                    <h2 className="text-xl font-semibold mb-2">Cash Flow Statement</h2>
                    <div className="space-y-1">
                        {earnings &&
                            earnings.cashFlowStatement &&
                            Object.entries(earnings.cashFlowStatement).map(
                                (b) =>
                                    b[0] !== 'fiscalDateEnding' && (
                                        <div
                                            key={b[0]}
                                            className="flex justify-between text-gray-700"
                                        >
                                            <p
                                                className="truncate w-1/2"
                                                onMouseOver={(e) => {
                                                    showTooltip(camelCaseToTitleCase(b[0]), e);
                                                }}
                                                onMouseOut={hideTooltip}
                                            >
                                                {camelCaseToTitleCase(b[0])}
                                            </p>
                                            <p className="truncate w-1/2 text-right">
                                                {formatAmount(b[0], b[1])}
                                            </p>
                                        </div>
                                    )
                            )}
                    </div>
                </div>
            </div>

            {/* Tooltip */}
            {tooltip.visible && (
                <div
                    className="absolute bg-gray-700 text-white p-2 rounded shadow-md text-sm"
                    style={{ top: tooltip.position.y - 75, left: tooltip.position.x - 10 }}
                >
                    {tooltip.title}
                </div>
            )}
        </div>
    );
};
