import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import NotificationPopup from "./notification-popup-component"
import { Conversation } from "../api/models/Conversation"
export const MainBackground = ({ children }:
    {
        children: any;
    }) => {
    const navigate = useNavigate();

    const currentPath = window.location.pathname;
    const queryParams = new URLSearchParams(window.location.search);
    const selectedMobileViews = queryParams.get('view')?.split(',') || getDefaultSelectedViews();

    // Step 1: Define State for Selected Views
    const [selectedViews, setSelectedViews] = useState<string[]>(selectedMobileViews || []);

    // Step 2: Define Available Views
    const availableViews = [
        { label: 'Earnings', value: 'earnings' },
        { label: 'LLM Convo', value: 'conversation' },
        { label: 'Transcript', value: 'transcript' },
    ];

    // Step 3: Handle Change Event
    const handleViewChange = (viewValue: string) => {
        setSelectedViews(prev => {
            if (prev.includes(viewValue)) {
                return prev.filter(v => v !== viewValue); // Uncheck
            } else {
                return [...prev, viewValue]; // Check
            }
        });
    };

    // Step 5: Render Checkboxes
    const renderViewCheckboxes = () => {
        return availableViews.map(view => (
            <div key={view.value} className="flex items-center mb-4">
                <input
                    type="checkbox"
                    id={view.value}
                    name={view.value}
                    checked={selectedViews.includes(view.value)}
                    onChange={() => handleViewChange(view.value)}
                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <label htmlFor={view.value} className="ml-2 text-sm font-medium text-gray-900">
                    {view.label}
                </label>
            </div>
        ));
    };

    function getDefaultSelectedViews() {
        if (window.innerWidth <= 768) {
            return ['conversation']
        }

        return ['earnings', 'conversation', 'transcript'];
    }

    // Step 6: Effect to Update Navigation
    useEffect(() => {
        if (currentPath !== '/dashboard') {
            return;
        }
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('views'); // Clear existing views
        if (selectedViews.length > 0) {
            queryParams.set('views', selectedViews.join(',')); // Add selected views
        }
        navigate('/dashboard' + '?' + queryParams.toString(), { replace: true });
    }, [selectedViews, navigate]);

    return (
        <div className="bg-white">
            <NotificationPopup />
            <div className="relative isolate">
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff7f50] to-[#ffa500] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                {children}
            </div>
        </div>
    )
}
