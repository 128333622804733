import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Conversation } from "../api/models/Conversation";

interface ConversationPopupProps {
  conversations: { id: string; name: string; ticker: string }[];
  onConvoClicked: (id: string) => void;
  tickers: string[];
  onCreateConversation: (ticker: string) => void;
  position: { top: number; left: number }; // Position relative to the button
  currentConvo: Conversation | undefined;
  handleDeleteConversation: (id: string) => void;
}

export const ConversationPopup: React.FC<ConversationPopupProps> = ({
  conversations,
  onConvoClicked,
  tickers,
  onCreateConversation,
  position,
  currentConvo,
  handleDeleteConversation,
}) => {
  const [showTickerSelector, setShowTickerSelector] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState<string | null>(null);

  const handleTickerSelect = (ticker: string) => {
    setSelectedTicker(ticker);
    setShowTickerSelector(false);
    onCreateConversation(ticker);
  };

  return (
    <div
      className="absolute bg-white rounded-lg shadow-lg w-80 p-4 z-[12]"
      style={{
        maxHeight: '50vh',
        overflow: 'scroll',
        top: position.top - 10,
        left: position.left,
        transform: 'translateY(-100%)',
      }}
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Conversations</h2>
        <button
          onClick={() => setShowTickerSelector(!showTickerSelector)}
          className="p-2 rounded-full focus:outline-none"
        >
          <PlusCircleIcon className="w-6 h-6" />
        </button>
      </div>

      {showTickerSelector && (
        <div className="mt-2">
          <select
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            value={selectedTicker || ''}
            onChange={(e) => handleTickerSelect(e.target.value)}
          >
            <option value="" disabled>Select a ticker</option>
            {tickers.map((ticker) => (
              <option key={ticker} value={ticker}>
                {ticker}
              </option>
            ))}
          </select>
        </div>
      )}

      <ul className="mt-4 space-y-2">
        {conversations.map((convo) => (
          <li
            key={convo.id}
            className={`hover:bg-gray-100 p-2 rounded-md ${convo.id === currentConvo?.id ? 'bg-gray-100' : ''} flex justify-between items-center`}
          >
            <span className="cursor-pointer" onClick={() => onConvoClicked(convo.id)}>{convo.name}</span>
            <TrashIcon className="w-4 h-4 text-gray-400 cursor-pointer" onClick={() => handleDeleteConversation(convo.id)} />
          </li>
        ))}
      </ul>
    </div>
  );
};