
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { createCheckoutSession, signIn } from "../api/thunks/users";
import { setCredentials } from "../store/user-reducer";
import GoogleLoginButton from "./google-login-button";
import { setNotification } from "../store/notifications-reducer";
import NotificationPopup from "./notification-popup-component";
import { v4 } from "uuid";

export default function LoginComponent() {
  document.title = "Login | PocketQuant";
  const dispatch = useAppDispatch();
  const credentials = useAppSelector((state) => state.user.credentials);
  const query = new URLSearchParams(window.location.search);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = (e.currentTarget.elements[0] as HTMLInputElement).value;
    const password = (e.currentTarget.elements[1] as HTMLInputElement).value;
    signIn(dispatch, { email, password }).then(async (res) => {
      console.log('res = ', res);
      // window.location.href = "/dashboard?" + query.toString();
    }).catch((err) => {
      dispatch(setNotification({
        message: 'Failed to login',
        type: 'error'
      }))
    })
  }

  useEffect(() => {
    if (credentials) {
      window.location.href = "/dashboard?" + query.toString();
      return;
    }

    const idToken = query.get("id_token");
    const refreshToken = query.get("refresh_token");
    const error = query.get("error");

    if (idToken && refreshToken) {
      dispatch(setCredentials({ accessToken: idToken, refreshToken }));
    }

    if (error !== null) {
      dispatch(setNotification({
        message: error,
        type: 'error'
      }));
    }

  }, []);

  return (
    <>
      <NotificationPopup />
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <a href="/">
            <img
              className="mx-auto h-10 w-auto"
              src="pq-icon-512.png"
              alt="PocketQuant Logo"
            />
          </a>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="text-sm">
                  <a href="/password-reset" className="font-semibold text-orange-600 hover:text-orange-500">
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="mb-2 flex w-full justify-center rounded-md bg-orange-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              >
                Sign in
              </button>

              <GoogleLoginButton additionalStyles={{
                width: "100%",
              }} onClick={(e: any) => {
                e.preventDefault();
                // make request to {{baseUrl}}/v1/users/google, get url from response field "url" and navigate there
                fetch(`${process.env.REACT_APP_API_URL}/v1/users/google`, {
                  method: "GET",
                  headers: {
                    'X-Request-ID': v4(),
                    "Content-Type": "application/json",
                  },
                })
                  .then((res) => res.json())
                  .then((res) => {
                    window.location.href = res.url;
                  });
              }} />
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <a href="#" onClick={() => {
              window.location.href = "/signup?" + query.toString();
            }} className="font-semibold leading-6 text-orange-600 hover:text-orange-500">
              Sign Up
            </a>
          </p>
        </div>
      </div>
    </>
  )
}
