import { v4 } from "uuid";
import { Artifact } from "../models/Artifact";
import axios from "axios";

export const getAllArtifacts = async (accessToken: string): Promise<Artifact[]> => {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/v1/artifacts', {
        headers: {
            'Authorization': accessToken,
            'X-Request-ID': v4()
        }
    });

    if (response.status !== 200) {
        throw new Error('Failed to get artifacts');
    }

    return response.data as Artifact[];
}

export const deleteArtifact = async (artifactId: string, accessToken: string): Promise<void> => {
    const response = await axios.delete(process.env.REACT_APP_API_URL + `/v1/artifacts/${artifactId}`, {
        headers: {
            'Authorization': accessToken,
            'X-Request-ID': v4()
        }
    });

    if (response.status !== 204) {
        throw new Error('Failed to delete artifact');
    }
}

export const createArtifact = async (artifact: {
    artifactType: string,
    data: string,
    hint?: string,
}, accessToken: string): Promise<string> => {
    const response = await axios.post(process.env.REACT_APP_API_URL + '/v1/artifacts', artifact, {
        headers: {
            'Authorization': accessToken,
            'X-Request-ID': v4()
        }
    });

    if (response.status !== 201) {
        throw new Error('Failed to create artifact');
    }

    return response.data as string;
}

export const updateArtifact = async (artifact: Artifact, accessToken: string): Promise<void> => {
    const response = await axios.put(process.env.REACT_APP_API_URL + `/v1/artifacts/${artifact.id}`, artifact, {
        headers: {
            'Authorization': accessToken,
            'X-Request-ID': v4()
        }
    });

    if (response.status !== 204) {
        throw new Error('Failed to update artifact');
    }
}
