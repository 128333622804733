import { XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

interface FAQPopupProps {
    faqs: { id: string; question: string; answer: string }[];
    onFAQClicked: (id: string) => void;
    position: { top: number; left: number }; // Position relative to the button
    onClose: () => void; // Add an onClose prop to handle closing the popup
}

export const FAQPopup: React.FC<FAQPopupProps> = ({
    faqs,
    onFAQClicked,
    position,
    onClose,
}) => {
    const [showAnswer, setShowAnswer] = useState<{ [key: string]: boolean }>({});

    const toggleAnswer = (id: string) => {
        setShowAnswer((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    return (
        <div
            className="absolute bg-white shadow-lg rounded-lg p-4 z-10"
            style={{ top: position.top, left: position.left }}
        >
            <button
                className="absolute top-3 right-3 text-gray-500"
                onClick={onClose}
            >
                <XMarkIcon className="h-5 w-5" />
            </button>
            <h2 className="text-xl font-semibold mb-4">FAQs</h2>
            <ul>
                {faqs.map((faq) => (
                    <li key={faq.id} className="mb-2">
                        <button
                            className="text-left w-full"
                            onClick={() => {
                                onFAQClicked(faq.id);
                                toggleAnswer(faq.id);
                            }}
                        >
                            <div className="flex justify-between">
                                <span>{faq.question}</span>
                            </div>
                        </button>
                        {showAnswer[faq.id] && <p className="mt-2">{faq.answer}</p>}
                    </li>
                ))}
            </ul>
        </div>
    );
};