import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text, Cell } from 'recharts';
import { navigationRoutes } from '../constants/constants';

const data = [
    {
        name: 'PocketQuant',
        score: 87,
        fill: '#FF7658'
    },
    {
        name: 'GPT-4.0 w/ search',
        score: 31,
        fill: '#76A99D'
    },
    {
        name: 'GPT-4.0 w/o search',
        score: 28,
        fill: '#B0B0B0'
    },
    {
        name: 'Llama 2',
        score: 19,
        fill: '#B0B0B0'
    }
];

const renderCustomBarLabel = ({ x, y, width, value }: { x: number, y: number, width: number, value: string }) => {
    return (
        <Text x={x + width / 2} y={y - 10} fill="#666" textAnchor="middle" dy={-6}>
            {value}
        </Text>
    );
};

export const ComparisonComponent = ({showNav} : {showNav : boolean}) => {
    const localNavigation = navigationRoutes.filter(e => e.href != '/pq-v-chatgpt')
    localNavigation.push({ name: "Sign Up", href: "/signup" });
    document.title = "PocketQuant vs. LLMs | PocketQuant";

    return (
        <>
            {showNav && (
                <header className="inset-x-0 p-6 lg:px-8 top-0 z-50">
                    <nav className="flex items-center justify-center" aria-label="Global">
                        <div className="lg:flex lg:gap-x-12">
                            {localNavigation.map((item) => (
                                <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    </nav>
                </header>
            )}
            <h2 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333', fontSize: '40px' }}>PocketQuant v. LLMs</h2>
            <h3 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#333', fontSize: '20px' }}>Over 2x more accurate</h3>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px', borderRadius: '8px' }}>
                <div style={{ width: '60%' }}>
                    <ResponsiveContainer width="100%" height={400} style={{ background: 'transparent' }}>
                        <BarChart
                            data={data}
                            margin={{
                                top: 20, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="name" tick={{ fontSize: 18, fontFamily: 'Arial, sans-serif', fill: '#666' }} />
                            <YAxis
                                type="number"
                                tick={{ fontSize: 18, fontFamily: 'Arial, sans-serif', fill: '#666' }}
                            />
                            <Tooltip />
                            <Bar dataKey="score" barSize={60}>
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.fill} />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                    <p style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', fontSize: '18px', color: '#666', marginTop: '10px' }}>
                        Based on FinanceBench Top 100 Questions.
                    </p>
                </div>
            </div>
        </>
    );
}
