import { useState } from "react";
import { Transcript } from "../api/models/Transcript";
import { EarningsDate } from "../api/models/EarningsDate";

export const TranscriptComponent = ({ ticker, transcript, currentFiscalDateEnding, setCurrentFiscalDateEnding, earningsDates }:
    {
        ticker: string;
        transcript: Transcript | undefined;
        setCurrentFiscalDateEnding: (date: string) => void;
        currentFiscalDateEnding: string
        earningsDates: EarningsDate[]
    }) => {
    const [tooltip, setTooltip] = useState({ visible: false, title: '', position: { x: 0, y: 0 } });

    const showTooltip = (title: string, event: React.MouseEvent) => {
        setTooltip({
            visible: true,
            title: title,
            position: { x: event.clientX, y: event.clientY }
        });
    };

    const hideTooltip = () => {
        setTooltip({ visible: false, title: '', position: { x: 0, y: 0 } });
    };

    return (
        <div className="flex flex-col h-full rounded-lg flex-1">
            {/* Sticky Header */}
            <div className="flex justify-between items-center mb-4 sticky top-0 z-10 p-4">
                <h1 className="text-2xl font-bold">Transcript</h1>
                <div className="relative w-48">
                    <select
                        className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 appearance-none"
                        value={currentFiscalDateEnding}
                        onChange={(e) => {
                            setCurrentFiscalDateEnding(e.target.value);
                        }}
                    >
                        {earningsDates.map((item) => (
                            <option
                                disabled={!item.userHasAccess}
                                key={item.date}
                                value={item.date}
                            >
                                {item.date}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Scrollable Transcript Section */}
            {transcript === undefined && (
                <div className="flex-1 overflow-auto">
                    Transcript not available for {currentFiscalDateEnding || ticker}
                </div>
            )}
            {transcript !== undefined && (
                <div
                    className="flex-1 flex flex-col items-start space-y-2 mt-3 scroll-smooth overflow-auto p-4"
                >
                    {transcript?.paragraphs?.map((p, i) => {
                        const participant = transcript?.participants?.find(
                            (x) => x.name === p.speaker
                        );
                        return (
                            <div
                                key={`${p.id}`}
                                className={`${!p.title?.includes("Analyst")
                                        ? "bg-gray-200 rounded-lg py-2 px-4 max-w-xs"
                                        : "bg-blue-500 rounded-lg py-2 px-4 max-w-xs text-white self-end"
                                    }`}
                            >
                                <p
                                    onMouseOver={(e) => {
                                        if (participant?.name === "Operator") {
                                            return;
                                        }
                                        showTooltip(participant?.title || "No title available", e);
                                    }}
                                    onMouseOut={hideTooltip}
                                    className="font-bold cursor-pointer underline"
                                >
                                    {p.speaker}:
                                </p>
                                <br />
                                <p>{p.text}</p>
                            </div>
                        );
                    })}
                </div>
            )}

            {/* Tooltip */}
            {tooltip.visible && (
                <div
                    className="absolute bg-gray-700 text-white p-2 rounded shadow-md text-sm"
                    style={{
                        top: tooltip.position.y - 75,
                        left: tooltip.position.x - 10,
                    }}
                >
                    {tooltip.title}
                </div>
            )}
        </div>
    );
};