import React, { useEffect, useState } from "react";
import { EarningsCalendarItem } from "../api/models/EarningsCalendar";
import "react-calendar/dist/Calendar.css";
import { navigationRoutes } from "../constants/constants";

export const EarningsCalendarComponent = ({
    calendar,
}: {
    calendar: EarningsCalendarItem[];
}) => {
    const today = new Date().toISOString().split("T")[0];
    const [selectedDay, setSelectedDay] = useState<string | null>(today); // Selected day
    const [selectedTickers, setSelectedTickers] = useState<string[]>(calendar.find(e => {
        console.log('comparing ', e.date, today, e.date === today)
        if (e.date === today) {
            console.log(e.tickers)
        }
        return e.date === today
    })?.tickers || []); // Tickers for the selected day

    console.log('selectedTickers', selectedTickers)
    
    const handleDayClicked = (item: EarningsCalendarItem) => {
        if (item.date === selectedDay) {
            setSelectedDay(null);
            setSelectedTickers([]);
            return;
        }
        setSelectedDay(item.date);
        setSelectedTickers(item.tickers);
    };

    const getDay = (date: string) => parseInt(date.split("-")[2]); // Extract the day from YYYY-MM-DD

    const getDayName = (date: string) => {
        const [year, month, day] = date.split("-").map(Number);
        const d = new Date(year, month - 1, day); // Create Date object without timezone issues
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return dayNames[d.getDay()];
    };

    useEffect(() => {
        const todayTickers = calendar.find((e) => {
            console.log("comparing", e.date, today, e.date === today);
            if (e.date === today) {
                console.log(e.tickers);
            }
            return e.date === today;
        })?.tickers;
    
        if (todayTickers) {
            setSelectedTickers(todayTickers);
        }
    }, [calendar]); // Run whenever `calendar` changes

    return (
        <>
            <nav className="flex items-center justify-between p-6" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a href="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">PocketQuant</span>
                        <img className="h-8 w-auto" src="pq-icon-512.png" alt="" />
                    </a>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigationRoutes.map((item) => (
                        <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                            {item.name}
                        </a>
                    ))}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <a href="/signup" className="text-sm font-semibold leading-6 text-gray-900">
                        Sign Up <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </nav>

            <div className="h-[100vh] overflow-hidden">
                    {/* Calendar Grid */}
                    <div className="grid grid-cols-7 gap-2 p-4">
                        {calendar.map((item) => {
                            const isSelected = item.date === selectedDay;
                            return (
                                <div
                                    key={item.date}
                                    onClick={() => handleDayClicked(item)}
                                    className={`cursor-pointer p-4 border rounded-lg ${item.tickers.length === 0
                                        ? "bg-gray-100 text-gray-500"
                                        : "bg-white text-black"
                                        } ${isSelected ? "ring-2 ring-orange-500" : ""
                                        }`}
                                >
                                    <div className="flex items-center justify-between">
                                        <div className="text-lg font-bold">{getDay(item.date)}</div>
                                        <div className="text-sm">{getDayName(item.date)?.substring(0, 3)}</div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div className="text-sm whitespace-pre-wrap">{item.tickers.length}</div>
                                        <div className="text-sm xl:block overflow-hidden text-ellipsis whitespace-nowrap sm:max-w-[100px] md:max-w-[150px]">
                                            {item.tickers.slice(0, 3).join(", ")}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                {/* Earnings List */}
                {selectedDay && (
                    <div className="h-[40%] overflow-y-auto p-6 border-t bg-gray-50 rounded-t-lg">
                        <h2 className="text-lg font-semibold mb-4 text-gray-800">
                            Earnings for {getDayName(selectedDay)}, {selectedDay}
                        </h2>
                        {selectedTickers.length > 0 ? (
                            <ul className="divide-y divide-gray-200">
                                {selectedTickers.sort().map((ticker) => (
                                    <li
                                        key={ticker}
                                        className="flex items-center justify-between py-2 px-4 bg-white hover:bg-gray-100 rounded-md shadow-sm transition-all duration-150"
                                    >
                                        <span key={ticker} className="text-sm font-medium text-gray-700">{ticker}</span>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-sm text-gray-500">No earnings reported for this day.</p>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};
