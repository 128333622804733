import { createAsyncThunk } from "@reduxjs/toolkit";
import { SignInResponse } from "../models/SignInResponse";
import { RootState } from "../../store/store";
import axios from "axios";
import { SigninInput } from "../models/SigninInput";
import { PostOkResponse } from "../models/PostOkResponse";
import { CreateUserInput } from "../models/CreateUserInput";
import { User } from "../models/User";
import { v4 } from "uuid";
import { setCredentials, setUser } from "../../store/user-reducer";

export const signIn = async (dispatch: (action: any) => void, input: SigninInput): Promise<{ tokens: SignInResponse, user: User }> => {
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/v1/users/signin', {
            email: input.email,
            password: input.password
        },
            {
                headers: {
                    'X-Request-ID': v4()
                }
            });

        if (response.status !== 200) {
            throw new Error('Failed to sign in');
        }

        const userRes = await axios.get(process.env.REACT_APP_API_URL + '/v1/users', {
            headers: {
                'Authorization': response.data.accessToken,
                'X-Request-ID': v4()
            }
        });

        if (userRes.status !== 200) {
            throw new Error('Failed to get user');
        }

        const result = { tokens: response.data, user: userRes.data };
        dispatch(setCredentials(result.tokens));
        dispatch(setUser(result.user));
        return result;
    } catch (error) {
        console.log('login err = ', error)
        throw error;
    }
};

export const createUser = createAsyncThunk<PostOkResponse, CreateUserInput, { state: RootState }>(
    'user/createUser',
    async (input) => {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/v1/users', input, {
            headers: {
                'X-Request-ID': v4()
            }
        });

        if (response.status !== 201) {
            throw new Error('Failed to create user');
        }

        console.log('window = ', window, 'data layer = ', (window as any).dataLayer)
        if (window && (window as any).dataLayer) {
            console.log('pushing data layer event');
            (window as any).dataLayer.push({
                'event': 'basic-plan-purchase', // This event name should really be changed lol
                'user_id': response.data.id,
            });
            console.log('done pushing data layer event');
        }

        return response.data as PostOkResponse;
    }
);

// TODO: Generate input field for this thunk
export const forgotPassword = createAsyncThunk<PostOkResponse, string, { state: RootState }>(
    'user/forgotPassword',
    async (email) => {
        const response = await axios.post(process.env.REACT_APP_API_URL + '/v1/users/password/reset', {
            email: email
        }, {
            headers: {
                'X-Request-ID': v4()
            }
        });

        if (response.status !== 200) {
            throw new Error('Failed to send forgot password email');
        }

        return response.data as PostOkResponse;
    }
);

// TODO: Generate input field for this thunk
export const confirmForgotPassword = createAsyncThunk<PostOkResponse, { email: string, code: string, password: string }, { state: RootState }>(
    'user/confirmForgotPassword',
    async (input) => {
        const response = await axios.put(process.env.REACT_APP_API_URL + '/v1/users/password/reset', {
            email: input.email,
            code: input.code,
            newPassword: input.password
        }, {
            headers: {
                'X-Request-ID': v4()
            }
        });

        if (response.status !== 200) {
            throw new Error('Failed to confirm forgot password');
        }

        return response.data as PostOkResponse;
    }
);

export const changePassword = createAsyncThunk<void, { currentPassword: string, newPassword: string }, { state: RootState }>(
    'user/changePassword',
    async (input, { getState }) => {
        const credentials = getState().user.credentials;
        const response = await axios.put(process.env.REACT_APP_API_URL + '/v1/users/password', {
            oldPassword: input.currentPassword,
            newPassword: input.newPassword
        }, {
            headers: {
                'Authorization': credentials?.accessToken,
                'X-Request-ID': v4()
            }
        });

        if (response.status !== 200) {
            throw new Error('Failed to change password');
        }
    }
);

export const createCheckoutSession = createAsyncThunk<{ redirectUrl: string }, { productCode: string }, { state: RootState }>(
    'user/createCheckoutSession',
    async (input, { getState }) => {
        const credentials = getState().user.credentials;
        const response = await axios.post(process.env.REACT_APP_API_URL + '/v1/payments/checkout', {
            productCode: input.productCode
        }, {
            headers: {
                'Authorization': credentials?.accessToken,
                'X-Request-ID': v4()
            }
        });

        if (response.status !== 200) {
            throw new Error('Failed to create checkout session');
        }

        return response.data as { redirectUrl: string };
    }
);

export const cancelSubscription = createAsyncThunk<void, { reason: string, feedback: string }, { state: RootState }>(
    'user/cancelSubscription',
    async (input, { getState }) => {
        const credentials = getState().user.credentials;
        console.log(process.env.REACT_APP_API_URL + '/v1/subscriptions')
        const response = await axios.put(process.env.REACT_APP_API_URL + '/v1/subscriptions',
            input,
            {
                headers: {
                    'Authorization': credentials?.accessToken,
                    'X-Request-ID': v4()
                }
            });

        if (response.status !== 200) {
            throw new Error('Failed to cancel subscription');
        }
    }
);

export const getUser = createAsyncThunk<User, void, { state: RootState }>(
    'user/getUser',
    async (_, { getState }) => {
        const credentials = getState().user.credentials;
        const response = await axios.get(process.env.REACT_APP_API_URL + '/v1/users', {
            headers: {
                'Authorization': credentials?.accessToken,
                'X-Request-ID': v4()
            }
        });

        if (response.status !== 200) {
            throw new Error('Failed to get user');
        }

        return response.data as User;
    }
);

export const refreshTokens = createAsyncThunk<SignInResponse, void, { state: RootState }>(
    'user/refreshTokens',
    async (_, { getState }) => {
        const credentials = getState().user.credentials;
        const response = await axios.post(process.env.REACT_APP_API_URL + '/v1/users/refresh', {
            refreshToken: credentials?.refreshToken
        }, {
            headers: {
                'X-Request-ID': v4()
            }
        });

        if (response.status !== 200) {
            throw new Error('Failed to refresh tokens');
        }

        return response.data as SignInResponse;
    }
);

export const deleteAccount = createAsyncThunk<void, void, { state: RootState }>(
    'user/deleteAccount',
    async (_, { getState }) => {
        const credentials = getState().user.credentials;
        const response = await axios.delete(process.env.REACT_APP_API_URL + '/v1/users', {
            headers: {
                'Authorization': credentials?.accessToken,
                'X-Request-ID': v4()
            }
        });

        if (response.status !== 204) {
            throw new Error('Failed to delete account');
        }
    }
);