// @ts-nocheck
import { useEffect, useState } from 'react';
import { Message } from '../api/models/Message';
import { MessageContent } from '../api/models/MessageContent';
import { Conversation } from '../api/models/Conversation';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import { wrap } from 'module';
import { renderToString } from "react-dom/server";
import ImageModal, { ImgModalState } from './image-modal';
import { ArrowTopRightOnSquareIcon, ArrowDownTrayIcon, BookmarkIcon } from "@heroicons/react/24/solid"; // Importing icons
import { CodeInterpreterResults } from './code-modal';
import ReactMarkdown from 'react-markdown';
import { createArtifact } from '../api/thunks/artifacts';
import { useAppSelector } from '../store/hooks';
import { useDispatch } from 'react-redux';
import { createCheckoutSession } from '../api/thunks/users';

// Simple React component
export const LlmConvoComponent = ({ convo, sendMessage, message, setMessage, getCitationTextDetails, highlighter, setImgModalState, openCodeModal, createArtifact }:
    {
        convo: Conversation | undefined
        sendMessage: (message: string) => Promise<boolean>;
        message: string;
        setMessage: (message: string) => void;
        getCitationTextDetails: (conversationId: string, messageId: string, fileId: string) => Promise<string>;
        setImgModalState: (state: ImgModalState) => void;
        openCodeModal: (val: string) => void;
        createArtifact: (type: 'code' | 'image', data: string) => Promise<void>;
    }
) => {
    const sendMessageInput = document.getElementById('send-message-input');
    const messageDiv = document.getElementById('message-div');
    const [tooltip, setTooltip] = useState({ visible: false, title: '', position: { x: 0, y: 0 } });
    const [isShowingFullCitation, setIsShowingFullCitation] = useState(false);
    const [messageInFlight, setMessageInFlight] = useState(false);
    const [expandedDetails, setExpandedDetails] = useState<{ [key: string]: boolean }>({});
    const user = useAppSelector(state => state.user);
    const dispatch = useDispatch();

    const showTooltip = (title: string, event: React.MouseEvent) => {
        const tooltipHeight = 100; // Height of the tooltip in px
        const yOffset = 10; // Offset from the clicked citation

        setTooltip({
            visible: true,
            title: title,
            position: { x: event.clientX, y: event.clientY - tooltipHeight - yOffset } // Adjust tooltip position to appear above
        });
    };

    const hideTooltip = () => {
        setTooltip({ visible: false, title: '', position: { x: 0, y: 0 } });
    };

    const handleMessageInputChange = (e: any) => {
        setMessage(e.target.value);
    };

    const handleEnterPressed = (e: any) => {
        if (e.key === 'Enter') {
            sendMessageLocal();
        }
    };

    useEffect(() => {
        if (sendMessageInput) {
            sendMessageInput.addEventListener('keydown', handleEnterPressed, {
                passive: true
            });
        }

        return () => {
            if (sendMessageInput) {
                sendMessageInput.removeEventListener('keydown', handleEnterPressed);
            }
        };
    }, [sendMessageInput, message, convo]);

    useEffect(() => {
        if (messageDiv) {
            messageDiv.scrollTop = messageDiv.scrollHeight;
        }
    }, [messageDiv, convo]);

    function addCitationLinksToMessage(
        content: MessageContent,
        rootMessage?: Message,
        msgIdx: number
    ) {
        if (msgIdx === 0 && content.text) {
            return (
                <div
                    key="citation-1st-clause"
                    dangerouslySetInnerHTML={{ __html: content.text }}
                ></div>
            );
        }

        const processTextWithCitations = (text: string): React.ReactNode[] => {
            const citationRegex = /【(\d+:\d+)†(.+?)】/g;
            const parts: React.ReactNode[] = [];
            let lastIndex = 0;
            let partIndex = 0;

            for (const match of text.matchAll(citationRegex)) {
                const [citationText, ,] = match;
                const citation = content.fileCitations.find(
                    (c) => c.text === citationText
                );

                if (!citation) continue;

                const startIndex = match.index!;
                const endIndex = startIndex + citationText.length;

                // Push plain text before citation
                if (lastIndex < startIndex) {
                    parts.push(
                        <ReactMarkdown
                            key={`markdown-${partIndex++}`}
                            unwrapDisallowed
                            disallowedElements={['p']}
                            components={{
                                ul: ({ node, ...props }) => (
                                    <ul key={`ul-${partIndex}`} className="list-disc ml-5" {...props} />
                                ),
                                li: ({ node, ...props }) => (
                                    <li key={`li-${partIndex}`} className="mb-1" {...props} />
                                ),
                            }}
                        >
                            {text.slice(lastIndex, startIndex)}
                        </ReactMarkdown>
                    );
                }

                // Add the citation as a link
                parts.push(
                    <a
                        key={`citation-${citation.fileId}-${startIndex}-${endIndex}`}
                        id="citation-link"
                        className="text-blue-500 hover:underline cursor-pointer"
                        onClick={async (e) => {
                            const fullCitation = await getCitationTextDetails(
                                convo?.id || "",
                                rootMessage?.id || "",
                                citation.fileId || ""
                            );
                            setIsShowingFullCitation(true);
                            showTooltip(
                                `${citation.fileName.split(".")[0]} ${fullCitation}` ||
                                "Citation text came back empty. This could be due to a hallucination",
                                e
                            );
                        }}
                    >
                        {citation.fileName}
                    </a>
                );

                lastIndex = endIndex;
            }

            // Push remaining text after the last citation
            if (lastIndex < text.length) {
                parts.push(
                    <ReactMarkdown
                        key={`markdown-${partIndex++}`}
                        unwrapDisallowed
                        disallowedElements={['p']}
                        components={{
                            ul: ({ node, ...props }) => (
                                <ul key={`ul-${partIndex}`} className="list-disc ml-5" {...props} />
                            ),
                            li: ({ node, ...props }) => (
                                <li key={`li-${partIndex}`} className="mb-1" {...props} />
                            ),
                        }}
                    >
                        {text.slice(lastIndex)}
                    </ReactMarkdown>
                );
            }

            return parts;
        };

        const processLaTeXSections = (): React.ReactNode[] => {
            const latexRegex = /(\$\$[\s\S]*?\$\$|\\\[[\s\S]*?\\\]|\\\([\s\S]*?\\\))/g;
            const latexDelimiters = [
                { left: "$$", right: "$$", display: true },
                { left: "\\(", right: "\\)", display: false },
                { left: "\\[", right: "\\]", display: true },
            ];
            const newcontentParts: React.ReactNode[] = [];
            let lastIndex = 0;
            if (!content?.text && !content.images) {
                return newcontentParts;
            }

            if (content.text) {
                for (const match of content?.text?.matchAll(latexRegex)) {
                    const startIndex = match.index!;
                    const endIndex = startIndex + match[0].length;

                    // Add text with citations before the LaTeX block
                    if (lastIndex < startIndex) {
                        newcontentParts.push(
                            ...processTextWithCitations(content.text.slice(lastIndex, startIndex))
                        );
                    }

                    // Add the LaTeX block
                    newcontentParts.push(
                        <div
                            key={`latex-container-${startIndex}`}
                            style={{
                                overflowX: 'scroll'
                            }}>
                            <Latex delimiters={latexDelimiters} key={`latex-${startIndex}`}>
                                {match[0]}
                            </Latex>
                        </div>
                    );

                    lastIndex = endIndex;
                }
            }

            // Add any remaining text with citations after the last LaTeX block
            if (content?.text && lastIndex < content.text.length) {
                newcontentParts.push(
                    ...processTextWithCitations(content.text.slice(lastIndex))
                );
            }

            if (content?.images) {
                let imgIdx = 0;
                for (const image of content?.images) {
                    newcontentParts.push(
                        <div key={`${rootMessage.id}-image-container-${imgIdx++}`} className="mb-4 relative group">
                            {/* Image */}
                            <img
                                src={`data:image/png;base64, ${image}`}
                                alt={`Image`}
                                className="mb-2 cursor-pointer object-contain w-full max-h-64"
                                onClick={() => {
                                    setImgModalState({
                                        isImageModalOpen: true,
                                        modalImageSrc: `data:image/png;base64, ${image}`,
                                    });
                                }}
                            />

                            <div className="absolute bottom-2 right-2 flex space-x-4">
                                {/* Open Image Modal Button */}
                                <button
                                    className="bg-black bg-opacity-50 text-white flex items-center justify-center p-2 rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-white opacity-0 group-hover:opacity-100 transition-opacity"
                                    onClick={() => {
                                        setImgModalState({
                                            isImageModalOpen: true,
                                            modalImageSrc: `data:image/png;base64, ${image}`,
                                        });
                                    }}
                                >
                                    <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                                </button>

                                {/* Save Image Button */}
                                <button
                                    className="bg-black bg-opacity-50 text-white flex items-center justify-center p-2 rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-white opacity-0 group-hover:opacity-100 transition-opacity"
                                    onClick={() => {
                                        const link = document.createElement("a");
                                        link.href = `data:image/png;base64, ${image}`;
                                        link.download = "image.png"; // Default filename
                                        link.click();
                                    }}
                                >
                                    <ArrowDownTrayIcon className="w-5 h-5" />
                                </button>

                                {/* Bookmark Button */}
                                <button
                                    className="bg-black bg-opacity-50 text-white flex items-center justify-center p-2 rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-white opacity-0 group-hover:opacity-100 transition-opacity"
                                    onClick={() => {
                                        console.log('root message = ', rootMessage);
                                        createArtifact("image", image, rootMessage?.content?.find(c => c.text && c?.text?.length > 0)?.text)
                                    }}
                                >
                                    <BookmarkIcon className="w-5 h-5" />
                                </button>
                            </div>
                        </div>
                    );
                }
            }

            return newcontentParts;
        };

        return <>
            {processLaTeXSections()}
        </>
    }

    const sendMessageLocal = async () => {
        setMessageInFlight(true);
        const res = await sendMessage(message);
        if (res) {
            setMessage('');
        }
        setMessageInFlight(false);
    }

    const handleToggleDetails = (id: string) => {
        setExpandedDetails(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const formatFunctionName = (str) => {
        // Replace underscores with spaces, then add space before capital letters and convert to lowercase
        return str
            .replace(/_/g, ' ')                    // Replace underscores with spaces
            .replace(/([a-z])([A-Z])/g, '$1 $2')    // Add space before capital letters
            .toLowerCase();                        // Convert the entire string to lowercase
    };

    const renderRunDetails = (msg: Message) => {
        const allFunctionCalls = msg.runDetails.flatMap(detail =>
            detail.type === "tool_calls"
                ? detail.tool_calls.filter(call => call.type === "function")
                : []
        );

        const allFileSearchCalls = msg.runDetails.flatMap(detail =>
            detail.type === "tool_calls"
                ? detail.tool_calls.filter(call => call.type === "file_search")
                : []
        );

        const allCodeInterpreterCalls = msg.runDetails.flatMap(detail =>
            detail.type === "tool_calls"
                ? detail.tool_calls.filter(call => call.type === "code_interpreter")
                : []
        );

        const funcId = `function-calls-group-${msg.id}`;
        const fileSearchId = `file-search-group-${msg.id}`;
        const codeInterpreterId = `code-interpreter-group-${msg.id}`;

        return (
            <div key={funcId} className="mt-4">
                {/* Subtle hint for Function Calls */}
                {allFunctionCalls.length > 0 && (
                    <div className="mb-4 p-2 border border-gray-200 rounded bg-gray-50">
                        <div
                            className="tool-title text-sm text-gray-500 cursor-pointer"
                            onClick={() => handleToggleDetails(funcId)}
                        >
                            {expandedDetails[funcId] ? "▲ Hide Tool Use" : "► Show Tool Use"}
                        </div>
                        {expandedDetails[funcId] && (
                            <div className="ml-4 mt-2 text-gray-700">
                                {allFunctionCalls.map((call, idx) => {
                                    const callId = call.id;
                                    return (
                                        <div key={callId} className="mb-2">
                                            <div
                                                className="tool-title text-blue-400 cursor-pointer"
                                                onClick={() => handleToggleDetails(callId)}
                                            >
                                                {formatFunctionName(call.function.name)}
                                            </div>
                                            {expandedDetails[callId] && (
                                                <div className="ml-4 mt-2 p-2 border-l-4 border-blue-300 bg-blue-50 rounded">
                                                    <p style={{ overflowWrap: "break-word", whiteSpace: "pre-wrap" }}>
                                                        <strong>Arguments:</strong>
                                                        <br />
                                                        <code style={{ fontSize: "12px" }}>
                                                            {JSON.stringify(JSON.parse(call.function.arguments), null, 2)}
                                                        </code>
                                                    </p>
                                                    <p style={{ overflowWrap: "break-word", whiteSpace: "pre-wrap" }}>
                                                        <strong>Output:</strong> {call.function.output}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}

                {/* Subtle hint for File Search Results */}
                {allFileSearchCalls.length > 0 && (
                    <div className="mb-4 p-2 border border-gray-200 rounded bg-gray-50">
                        <div
                            className="tool-title text-sm text-gray-500 cursor-pointer"
                            onClick={() => handleToggleDetails(fileSearchId)}
                        >
                            {expandedDetails[fileSearchId]
                                ? "▲ Hide File Search Results"
                                : "► Show File Search Results"}
                        </div>
                        {expandedDetails[fileSearchId] && (
                            <div className="ml-4 mt-2 text-gray-700">
                                {allFileSearchCalls.map((call, idx) =>
                                    call.file_search.results.map((file, fileIdx) => {
                                        const fileId = `${fileSearchId}-file-${fileIdx}`;
                                        return (
                                            <div key={fileId}>
                                                <span
                                                    className="text-blue-400 cursor-pointer"
                                                    onClick={() => handleToggleDetails(fileId)}
                                                >
                                                    {file.file_name}
                                                </span>
                                                {expandedDetails[fileId] && (
                                                    <div className="ml-4 mt-1 p-2 border-l-4 border-blue-300 bg-blue-50">
                                                        {file.content.map((contentItem, contentIdx) => (
                                                            <p key={contentIdx} style={{ overflowWrap: "break-word" }}>
                                                                {contentItem.text}
                                                            </p>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        )}
                    </div>
                )}

                {/* Subtle hint for Code Interpreter Results */}
                {allCodeInterpreterCalls.length > 0 && (
                    <CodeInterpreterResults
                        expandedDetails={expandedDetails}
                        handleToggleDetails={handleToggleDetails}
                        id={codeInterpreterId}
                        allCodeInterpreterCalls={allCodeInterpreterCalls} highlighter={highlighter}
                        openCodeModal={openCodeModal}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <div className="flex flex-col h-full">
                {/* Sticky Header */}
                <div className="flex items-center mb-4 sticky top-0 z-10 p-4">
                    <img
                        src={`/company_icons/${convo?.ticker}.png`}
                        alt={`${convo?.ticker}-logo`}
                        className="w-8 h-8"
                    />
                    <h1 className="text-2xl font-bold ml-4">{convo?.ticker}</h1>
                    {user?.user?.plan === "free" && (
                        <a
                            href="#"
                            onClick={async () => {
                                if (process.env.REACT_APP_PQ_PREMIUM_PRICE_ID === undefined) {
                                    alert("Failed to create checkout session, price id not defined");
                                    return;
                                }
                                const res = await dispatch(
                                    createCheckoutSession({
                                        productCode: process.env.REACT_APP_PQ_PREMIUM_PRICE_ID,
                                    })
                                );
                                if (res.meta.requestStatus === "fulfilled") {
                                    window.location.href = (res.payload as any).redirectUrl;
                                } else {
                                    alert("Failed to create checkout session");
                                }
                            }}
                            className="ml-auto flex items-center gap-2 px-3 py-1 text-orange-600 font-semibold border-2 border-orange-600 rounded-lg hover:bg-orange-50 transition"
                        >
                            <span className="text-purple-600 text-sm">💎</span>
                            Upgrade
                        </a>
                    )}
                </div>


                {/* Scrollable Messages Section */}
                <div
                    id="message-div"
                    className="flex-1 flex flex-col items-start space-y-2 mt-3 scroll-smooth overflow-auto p-4"
                >
                    {convo &&
                        convo.messages &&
                        convo.messages.map((msg: any, msgIdx: number) => {
                            if (msg.role === Message.RoleEnum.User) {
                                return (
                                    <div
                                        key={`msg-${msg.id}`}
                                        className="bg-blue-500 rounded-lg py-2 px-4 max-w-xs text-white self-end"
                                    >
                                        {msg.content.map((c: any, i: any) => (
                                            <p key={`content-${msg.id}-${i}`}>{c.text}</p>
                                        ))}
                                    </div>
                                );
                            }
                            return (
                                <div
                                    key={`msg-${msg.id}-${msgIdx}`}
                                    className="bg-gray-200 rounded-lg py-2 px-4 max-w-xs"
                                    style={{
                                        overflowWrap: 'break-word',
                                    }}
                                >
                                    {msg.content.map((c: any, i: any) =>
                                        addCitationLinksToMessage(c, msg, msgIdx)
                                    )}
                                    {msg.runDetails && renderRunDetails(msg)}
                                </div>
                            );
                        })}
                </div>

                {/* Sticky Footer */}
                <div className="sticky bottom-0 bg-white z-10 pl-4 pr-4 pt-4">
                    <div className="relative">
                        <input
                            id="send-message-input"
                            type="text"
                            className="w-full border-2 rounded-lg p-2"
                            placeholder="Type a message..."
                            onChange={handleMessageInputChange}
                            value={message}
                            data-hj-allow
                        />
                        <button
                            disabled={messageInFlight}
                            onClick={sendMessageLocal}
                            className="absolute right-2 top-2 text-blue-500 hover:underline"
                        >
                            Send
                        </button>
                    </div>
                    {/* Warning Message */}
                    <div className="flex items-center mt-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z" fill="orange" />
                        </svg>
                        <p
                            className="text-sm text-gray-500 flex items-center"
                            style={{
                                maxHeight: '4vh',
                                overflow: 'auto',
                            }}
                        >
                            AI makes mistakes. Check the facts.
                        </p>
                    </div>
                </div>
            </div >
            {
                tooltip.visible && (
                    <div
                        className="absolute bg-white border border-gray-300 rounded-lg p-2 shadow-lg overflow-y-auto"
                        style={{
                            top: tooltip.position.y,
                            left: tooltip.position.x,
                            maxHeight: '200px', // Adjust the height as needed
                            width: '200px', // Adjust the width as needed
                            zIndex: 1000
                        }}
                        onMouseLeave={hideTooltip}
                    >
                        <div className="text-sm text-gray-700" dangerouslySetInnerHTML={{ __html: tooltip.title }}></div>
                    </div>
                )
            }
        </>
    );
};
