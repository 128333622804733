import { BookmarkIcon } from "@heroicons/react/24/solid";
import React, { useEffect } from "react";

interface CodeModalProps {
    isOpen: boolean;
    code: string;
    onClose: () => void;
    language?: string;
    highlighter: any; // Adjust this type based on the highlighter you're using
    openCodeModal: (code: string) => void;
    createArtifact: (artifactType: 'code' | 'image', data: string) => void;
}

export const CodeModal: React.FC<CodeModalProps> = ({ openCodeModal, isOpen, code, onClose, language = "python", highlighter, createArtifact }) => {
    const highlightedCode = highlighter.codeToHtml(code, { lang: language, theme: "github-dark" });

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose(); // Close the modal when Escape is pressed
            }
        };

        if (isOpen) {
            window.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen, onClose]);
    if (!isOpen) return null;
    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
            onClick={onClose}
        >
            <div
                className="relative max-w-[90vw] max-h-[90vh] overflow-auto rounded-lg shadow-lg"
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
            >
                <div className="p-4" style={{
                    backgroundColor: "#24292e"
                }}>
                    <div
                        className="shiki-code"
                        dangerouslySetInnerHTML={{ __html: highlightedCode }}
                    ></div>
                </div>
                {/* Close Button */}
                <div className="h-full flex flex-col justify-between">
                    <button
                        className="cursor-pointer absolute top-4 right-4 bg-gray-800 text-white text-lg font-bold w-10 h-10 flex items-center justify-center rounded-full hover:bg-gray-700"
                        onClick={onClose}
                    >
                        ✕
                    </button>
                    <button
                        className="cursor-pointer mr-2 absolute top-4 right-14 bg-black bg-opacity-50 text-white flex items-center justify-center p-2 rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-white transition-opacity"
                        onClick={() => {
                            createArtifact('code', code)
                        }}
                    >
                        <BookmarkIcon className="w-5 h-5" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export const CodeInterpreterResults: React.FC<{
    id: string;
    allCodeInterpreterCalls: any[];
    handleToggleDetails: (id: string) => void;
    expandedDetails: Record<string, boolean>;
    openCodeModal: (code: string) => void;
}> = ({
    id,
    allCodeInterpreterCalls,
    handleToggleDetails,
    expandedDetails,
    openCodeModal,
}) => {
        return (
            <div>
                {allCodeInterpreterCalls.length > 0 && (
                    <div className="mb-4 p-2 border border-gray-200 rounded bg-gray-50">
                        <div
                            className="tool-title text-sm text-gray-500 cursor-pointer"
                            onClick={() => handleToggleDetails(id)}
                        >
                            {expandedDetails[id]
                                ? "▲ Hide Code"
                                : "► Show Code"}
                        </div>
                        {expandedDetails[id] && (
                            <div className="ml-4 mt-2 text-gray-700">
                                {expandedDetails[id] && allCodeInterpreterCalls.map((call, idx) => {
                                    const callId = call.id;
                                    const { input } = call.code_interpreter;
                                    return (

                                        <div key={callId}>
                                            <button
                                                className="text-sm text-blue-500 underline"
                                                onClick={() => openCodeModal(input)}
                                            >
                                                View Code
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };
